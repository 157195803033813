
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import InputNumber from "primevue/inputnumber"
import SalesService from "@/services/SalesService"
import router from "@/router/router"
import SelectButton from "primevue/selectbutton"
import OrganizationService from "@/services/OrganizationService"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"

export default defineComponent({
  props: {
    //if saleId the sale has just been created
    saleId: {
      type: String,
    },
    //if sale the entire sale object is available
    sale: { type: Object },
    quotation: {
      type: Object,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showSaveAndEditButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    organizationId: { type: String },
    organizationIdTemp: { type: String },
  },
  components: {
    flatPickr,
    InputNumber,
    SelectButton,
    PrimaryButton,
    SecondaryButton,
  },
  emits: ["close-and-reload"],
  setup(props, { emit }: any) {
    const salesService = new SalesService()
    let currentDate = new Date()
    const startDate = ref(props.quotation ? props.quotation.startDate : currentDate)
    const endDate = ref(
      props.quotation
        ? props.quotation.endDate
        : new Date(new Date().setMonth(currentDate.getMonth() + 2))
    )
    const reference = ref(props.quotation?.reference ?? "")
    const title = ref(props.quotation?.title ?? "")
    const name = ref(props.quotation?.name ?? "")
    const companyName = ref(props.quotation?.companyName ?? "")
    const address = ref(props.quotation?.address ?? "")
    const vat = ref(props.quotation?.vat ?? "")
    const languages = reactive([])
    const route = useRoute()
    const language = ref(props.quotation?.language ?? "EN")
    const notes = ref(props.quotation?.notes ?? "")

    salesService.getLanguages().then((_languages: any) => {
      languages.length = 0
      ;[].push.apply(languages, _languages)
    })

    function saveQuotation(callback: any) {
      const quotation = {
        startDate: startDate.value,
        endDate: endDate.value,
        title: title.value,
        name: name.value,
        companyName: companyName.value,
        address: address.value,
        vat: vat.value,
        language: language.value,
        notes: notes.value,
        reference: reference.value,
      }

      const saleId: string = props.saleId ?? props.sale?.id

      if (props.quotation) {
        //update quotation
        salesService.updateQuotation(props.quotation.id as string, quotation)
      } else {
        //create quotation
        salesService.createQuotation(saleId, quotation).then((quotationId: string) => {
          emit("close-and-reload")
          callback && typeof callback === "function" && callback(quotationId)
        })
      }
    }

    function saveQuotationAndEdit() {
      saveQuotation((quotationId: string) => {
        emit("close-and-reload")
        //route to edit quotation location
        let orgId
        if (props.organizationId) {
          orgId = props.organizationId
        } else {
          orgId = props.organizationIdTemp
        }
        router.push({ path: "/org/" + orgId + "/sales/quotations/quotationeditor/" + quotationId })
      })
    }

    const organizationService = OrganizationService.getInstance()
    const addressOptions = reactive([])
    const orgTemp = reactive({ name: "", part: "", vat: "" })
    const orgAdressTemp = ref("")

    populateFormWithOrgInfo()
    function populateFormWithOrgInfo() {
      addressOptions.length = 0
      let orgId = props.organizationId ? props.organizationId : props.organizationIdTemp
      if (orgId !== null && !route.fullPath.includes("/quotationeditor/")) {
        organizationService.getOrganization(orgId as string).then((org) => {
          Object.assign(orgTemp, org)
          if (name.value === "") {
            name.value = org.name
          }
          if (companyName.value === "") {
            companyName.value = org.company.name
          }
          if (vat.value === "") {
            vat.value = org.vat
          }
          language.value = org.language
        })

        organizationService.getAddresses(orgId as string, 0, "").then((result) => {
          let combinedAddressString =
            result.rows[0].line1 +
            " " +
            result.rows[0].line2 +
            " " +
            result.rows[0].line3 +
            " , " +
            result.rows[0].zip +
            " " +
            result.rows[0].city +
            " , " +
            result.rows[0].country
          orgAdressTemp.value = combinedAddressString
          address.value = combinedAddressString
        })
      }
    }

    const flatPickConfig = ref({
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
    })

    const templates = reactive([])
    const showTemplateImport = ref(false)
    const selectedTemplate = ref()
    const inEditor = computed(() => {
      return route.fullPath.includes("quotationeditor")
    })

    getTemplates()
    function getTemplates() {
      salesService.getQuotationTemplates().then((result) => {
        ;[].push.apply(templates, result)
      })
    }

    function importTemplate() {
      if (selectedTemplate.value) {
        salesService
          .addQuotationFromTemplate(selectedTemplate.value.id, props.saleId as string)
          .then((result) => {
            salesService.getQuotationDetails(result).then((res) => {
              let newQuotationObject = res
              newQuotationObject.name = orgTemp.name ?? name.value
              newQuotationObject.companyName = orgTemp.part ?? companyName.value
              newQuotationObject.vat = orgTemp.vat ?? companyName.value
              newQuotationObject.address = orgAdressTemp.value ?? address.value
              newQuotationObject.title = title.value
              newQuotationObject.language = language.value
              newQuotationObject.startDate = startDate.value
              newQuotationObject.endDate = endDate.value
              salesService.updateQuotation(newQuotationObject.id, newQuotationObject).then(() => {
                showTemplateImport.value = false
                emit("close-and-reload")
                router.push({
                  path:
                    "/org/" +
                    (props.organizationId ?? props.organizationIdTemp) +
                    "/sales/quotations/quotationeditor/" +
                    newQuotationObject.id,
                })
              })
            })
          })
      }
    }

    return {
      flatPickConfig,
      startDate,
      endDate,
      title,
      companyName,
      name,
      address,
      notes,
      vat,
      saveQuotation,
      saveQuotationAndEdit,
      language,
      languages,
      importTemplate,
      templates,
      showTemplateImport,
      selectedTemplate,
      inEditor,
      addressOptions,
    }
  },
})
