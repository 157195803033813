
import SalesService from "@/services/SalesService"
import { reactive, ref, computed } from "vue"
import SalesQuotationComponent from "./salesQuotationComponent.vue"
import SalesQuotationProductsComponent from "./salesQuotationProductsComponent.vue"
import { useToast } from "primevue/usetoast"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  setup() {
    const toast = useToast()
    const salesService = new SalesService()
    const templates = reactive([])
    const showDetails = ref(false)
    const activeCurrency = computed(() => {
      return templateForDetails.value?.sale?.currency
    })

    const quotationProductsApi: any = {
      loadImages: null,
    }

    const languages = reactive([])

    salesService.getLanguages().then((_languages: any) => {
      languages.length = 0
      ;[].push.apply(languages, _languages)
    })

    getTemplates()
    function getTemplates() {
      templates.length = 0
      salesService.getQuotationTemplates().then((result) => {
        ;[].push.apply(templates, result)
      })
    }

    const templateForDetails = ref()

    function openTemplate(template: any) {
      salesService.getQuotationTemplateDetails(template.id).then((result) => {
        templateForDetails.value = result
        showDetails.value = true
        quotationProductsApi.loadImages && quotationProductsApi.loadImages()
      })
    }

    function updateTemplate(id: string, data: any) {
      salesService.updateQuotationTemplate(id, data).then(() => {
        getTemplates()
      })
    }

    function deleteTemplate(id: string) {
      salesService.deleteQuotationTemplate(id).then(() => {
        getTemplates()
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Template deleted",
        })
        templateForDetails.value = null
        showDetails.value = false
      })
    }

    return {
      templates,
      openTemplate,
      templateForDetails,
      showDetails,
      activeCurrency,
      quotationProductsApi,
      languages,
      deleteTemplate,
      updateTemplate,
    }
  },
  components: {
    SalesQuotationComponent,
    SalesQuotationProductsComponent,
    PrimaryButton,
  },
}
